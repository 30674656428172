<template>
	<div class="loginbox">
		<!-- <div class="title">
			<img src="../assets/imgs/login_tit.png" alt="">
		</div> -->
		<div class="loginform">
			<div class="from">
				<img class="logintitle" src="../assets/imgs/login_title.png" alt="">
				<div class="cont">
					<div class="dl">
						<div>登录</div>
					</div>
					<div class="bd">
						<el-input size="large" placeholder="请输入账号" prefix-icon="el-icon-user-solid" v-model="username">
						</el-input>
						<el-input size="large" placeholder="请输入密码" prefix-icon="el-icon-search" type="password" v-model="password">
						</el-input>
						<div class="clearfix" style="margin-top: 15px;">
							<el-input size="large" style="float:left;width: calc(100% - 130px);margin-top: 8px;" placeholder="请输入验证码" oninput="value=value.replace(/[^\d]/g,'')" v-model="confirm">
							</el-input>
							<div class="code" style="float: right;" @click="refreshCode">
								<s-identify :identifyCode="identifyCode"></s-identify>
							</div>
						</div>
						<el-button @click="gotoLogin" :loading="loading" size="large" class="btn" type="primary">
							<span v-if="!loading">立即登录</span>
							<span v-else>登录中</span>
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getCodeImg
	} from '@/api/login'
	import SIdentify from "../components/common/identify.vue";
	import Cookies from "js-cookie";
	export default {
		name: "login",
		components: {
			SIdentify: SIdentify,
		},
		data() {
			return {
				username: "",
				password: "",
				confirm: "",
				code: "",
				checked: false,
				token: this.GLOBAL.token,
				identifyCode: "",
				times: "",
				loading: false,
				rememberMe: false
			};
		},
		created() {
			this.getCookie();
			this.refreshCode();

		},
		methods: {
			//获取cookie
			getCookie() {
				const username = Cookies.get("username");
				const rememberMe = Cookies.get('rememberMe')
				this.username = username === undefined ? this.username : username,
					this.rememberMe = rememberMe === undefined ? false : Boolean(rememberMe)
			},
			//获取验证码
			refreshCode() {
				getCodeImg().then(res => {
					this.identifyCode = res.detail.vcode;
					this.times = res.detail.times;
				});
			},
			//登录
			gotoLogin() {
				var that = this;
				if (this.username.trim() == "") {
					this.$message({
						message: "用户名不能为空",
						type: "warning",
					});
					return;
				}
				if (this.password.trim() == "") {
					this.$message({
						message: "密码不能为空",
						type: "warning",
						"x-access-token": "",
					});
					return;
				}
				if (this.confirm.trim() == "") {
					this.$message({
						message: "验证码不能为空",
						type: "warning",
					});
					return;
				}
				if (this.confirm != this.identifyCode) {
					this.$message({
						message: "验证码错误",
						type: "warning",
					});
					return;
				}

				if (this.rememberMe) {
					Cookies.set("username", this.username, {
						expires: 30
					});
					Cookies.set('rememberMe', this.rememberMe, {
						expires: 30
					});
				} else {
					Cookies.remove("username");
					Cookies.remove('rememberMe');
				}

				this.loading = true;
				let loginForm = {
					username: this.username,
					password: this.password,
					confirm: this.confirm,
					times: this.times
				}
				this.$store.dispatch("Login", loginForm).then((res) => {
					console.log('res', res)
					let timer = setTimeout(() => {
						this.$router.push({
								path: "/index"
						}).catch(() => {});
						clearTimeout(timer)

					}, 1000)
				}).catch(() => {
					this.loading = false;
					this.refreshCode();
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.loginbox {
		width: 100%;
		height: 100%;
		background-image: url(../assets/imgs/login_bg.jpg), linear-gradient(to bottom, #010f33, #0172b4);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		min-width: 1200px;
		position: relative;

		.title {
			position: absolute;
			top: 14%;
			left: 0;
			right: 0;

			img {
				display: block;
				width: 740px;
				height: auto;
				margin: auto;
			}
		}
	}

	.loginform {
		width: 1200px;
		margin: auto;
		height: 100%;
		position: relative;
	}

	.loginbox .from {
		width: 387px;
		height: 600px;
		position: absolute;
		top: 8%;
		right: 36px;
		margin: auto;
		bottom: 0;
		.logintitle{
			display: block;
			width: 387px;
			height: 44px;
			margin-bottom: 30px;
		}
		.cont{
			width: 387px;
			height: 360px;
			background: #fff;
			box-shadow: 0 5px 5px 3px rgba(0, 0, 0, 0.1);
			padding: 32px 40px;
			box-sizing: border-box;
			z-index: 10;
		}
		/* background-image: linear-gradient(to bottom, rgba(17, 129, 209, 0.7), rgba(13, 61, 148, 0.7)); */
		
	}
	.loginbox .dl {
		height: 32px;
		position: relative;
		border-bottom: 2px solid #d0d1d0;
		div{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			margin: auto;
			width: 85px;
			height: 32px;
			border-bottom: 3px solid #0e64e7;
			text-align: center;
			line-height: 32px;
			font-size: 18px;
			color: #000;
		}
	}

	.loginbox .dl span {
		font-size: 22px;
		letter-spacing: 1px;
	}


	.codebox {
		position: relative;
		padding-right: 120px;
	}

	.codebox img {
		position: absolute;
		right: 0;
		bottom: 1px;
		width: auto;
		height: 38px;
		z-index: 5;
		cursor: pointer;
	}

	.loginbox .btn {
		width: 100%;
		font-size: 16px;
		margin-top: 30px;
	}

	.loginbox .bd .el-input {
		margin-top: 15px;
	}


	.otherinput {
		margin-top: 10px;
		.el-checkbox__input.is-checked+.el-checkbox__label{
			color: #00c2ac !important;
		}
		.el-checkbox__label{
			color: #00c2ac !important;
		}
		.el-checkbox__inner{
			background-color: #00c2ac !important; 
		}
		.el-checkbox {
			color: #00c2ac;
		}
	}

	.checkbox {
		margin: 15px 0 5px;
	}

	.el-checkbox .el-checkbox__label {
		font-size: 12px !important;
	}

	.el-select {
		display: block;
	}

	.zhuce {
		display: block;
		margin-bottom: 10px;
		padding: 0 !important;
	}
</style>
